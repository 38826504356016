import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
// Components
import {
  Loading,
  PageHeader,
  PaymentDetails,
  Primary,
  Seo,
  ShippingDetails,
} from '../components'
import { Setup, Manage, DeleteAutoshipModal } from '../components/Autoship'
import infoIcon from '../svg/InfoIcon.svg'
// Contexts
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
import { useStoreContext } from '../context/StoreContext'
// Styles
import { Container, Wrapper } from '../styles/common'
// Utils
import { buildAutoShipItems } from '../utils/autoShipHelpers'
import { addOneDay } from '../utils/dateHelpers'

const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
`

const SubscriptionsPage = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isUpdatingAutoShip, setIsUpdatingAutoShip] = useState(false)
  const [cardData, setCardData] = useState(null)

  const {
    cardsOnFile,
    qUser,
    userType,
    isAuthenticated,
    isEventSite,
    isVipSite,
  } = useAuthContext()
  const { isStoreLoading } = useStoreContext()
  const {
    autoShipAddress,
    autoShipData,
    autoShipDate,
    autoShipId,
    autoShipItems,
    autoShipPaymentInformationId,
    isPcOfferInCart,
    isAmbOfferInCart,
    manageAutoShip,
    updateAutoShipState,
    handleSetAutoShipState,
  } = useCartContext()

  useEffect(() => {
    if (!autoShipPaymentInformationId) {
      setCardData(null)
      return
    }
    const card = cardsOnFile.find(({ associatePaymentInformationId }) => {
      return associatePaymentInformationId === autoShipPaymentInformationId
    })
    setCardData(card)
  }, [autoShipPaymentInformationId])

  const { prismicData } = usePrismic()
  const { continue_shopping, loading, manage_autoship, setup_autoship } =
    prismicData.prismicAutoshipPage

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL')

  useEffect(() => {
    if (!showAutoShip) {
      navigate('/')
    }
  }, [])

  const createNewAutoShip = async () => {
    setIsUpdatingAutoShip(true)

    const data = {
      address: autoShipAddress,
      associateId: qUser.associateId,
      associatePaymentInformationId: autoShipPaymentInformationId,
      autoShipItems: buildAutoShipItems(autoShipItems),
      dateNextProcess: autoShipDate,
      dateStart: autoShipDate,
      isActive: true,
    }
    await manageAutoShip
      .create(data, updateAutoShipState)
      .then(() => setIsUpdatingAutoShip(false))
  }

  const updateAutoShipItems = async () => {
    if (!autoShipId) return
    setIsUpdatingAutoShip(true)
    await manageAutoShip
      .updateItems(
        { autoShipId, autoShipItems: buildAutoShipItems(autoShipItems) },
        updateAutoShipState
      )
      .then(() => setIsUpdatingAutoShip(false))
  }

  const onClickDelete = () => {
    setOpenDeleteModal(true)
  }

  const onClickClose = () => {
    if (autoShipDate || !autoShipData) navigate(-1)
    else {
      handleSetAutoShipState({
        autoShipDate: addOneDay(autoShipData.dateNextProcess),
      })
    }
  }

  const handleButton = () => {
    if (isAuthenticated) {
      if (userType !== 'RETAIL') {
        return {
          content: autoShipId ? 'Continue Shopping' : 'Create Subscription',
          onClick: autoShipId ? () => navigate('/products') : createNewAutoShip,
          loading: isUpdatingAutoShip,
          disabled:
            !autoShipAddress ||
            !autoShipPaymentInformationId ||
            autoShipItems?.length === 0,
        }
      }
    } else {
      return {
        content: 'Sign in to begin',
        onClick: () => navigate('/login'),
      }
    }
  }

  if (isStoreLoading)
    return <Loading loading={isStoreLoading} message={loading[0].text} />

  return (
    <>
      <Seo title="Subscriptions" />
      <Wrapper>
        <PageHeader
          onClickClose={onClickClose}
          onClickDelete={autoShipId && onClickDelete}
        >
          {autoShipDate ? manage_autoship[0].text : setup_autoship[0].text}
        </PageHeader>
        {autoShipDate ? (
          <Manage>
            <Container>
              {isAuthenticated ? (
                <>
                  <ShippingDetails
                    address={autoShipAddress}
                    showAddress={!!autoShipAddress}
                    returnUrl="/subscriptions"
                  />
                  <PaymentDetails
                    ccType={cardData?.creditCardType}
                    lastFour={cardData?.lastFourDigits}
                    returnUrl="/subscriptions"
                  />
                </>
              ) : null}
              <Container align="center">
                {isAuthenticated && userType === 'RETAIL' ? (
                  <Container align="center" maxWidth="90%">
                    <Container row>
                      <InfoIcon src={infoIcon} />
                      <span style={{ fontStyle: 'italic' }}>
                        Once you have purchased your Preferred Customer
                        membership or Ambassador membership, return here to set
                        up your product subscription order.
                      </span>
                    </Container>
                    <Primary
                      onClick={() => navigate('/cart')}
                      content="Checkout now"
                      style={{ marginBottom: 0 }}
                    />
                    <Primary
                      onClick={() => navigate('/')}
                      content="Keep Shopping"
                    />
                  </Container>
                ) : (
                  <Primary {...handleButton()} />
                )}
              </Container>
            </Container>
          </Manage>
        ) : (
          <Setup>
            <Primary
              content={continue_shopping[0].text}
              onClick={() => navigate('/')}
            />
          </Setup>
        )}
        <DeleteAutoshipModal
          open={openDeleteModal}
          setShowModal={setOpenDeleteModal}
        />
      </Wrapper>
    </>
  )
}

export default SubscriptionsPage
